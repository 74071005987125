<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310.74 310.74">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <g>
          <polygon class="cls-1" points="182.74 91.18 128 74.92 106.43 109.82 159.22 126.08 182.74 91.18" />
          <polyline class="cls-1" points="106.43 109.82 106.43 232.54 159.22 248.8 159.22 126.08" />
          <polyline class="cls-1" points="182.74 91.18 182.74 78.19 128 61.93 128 74.92" />
          <polygon class="cls-1" points="159.22 126.08 204.3 113.07 204.3 235.79 159.22 248.8 159.22 126.08" />
          <line class="cls-1" x1="182.74" y1="91.18" x2="204.3" y2="113.07" />
        </g>
        <g>
          <circle class="cls-1" cx="155.37" cy="155.37" r="151.87" />
          <polyline class="cls-1" points="47.98 47.98 47.98 47.98 47.98 47.98 107.72 107.72" />
          <polyline class="cls-1" points="204.3 204.3 262.76 262.75 262.76 262.76 262.76 262.76" />
        </g>
      </g>
    </g>
  </svg>
</template>
<style scoped>
.cls-1 {
  fill: none;
  stroke: #267891;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6.99px;
}
</style>
